/*找技术*/
<template >
  <div class="talent-page-content">
    <t-nav
        :list="navData.navList"
        :name="navData.title"
        :defaultNav="current"
        @changeNav="changeNav"
    />
    <div class="talent-data-content" v-show="!detailShow">
      <div class="talent-data-title">{{ activeName }}</div>
      <div class="talent-data-search">
        <a-input-search
            class="talent-nav-search-input"
            placeholder="请输入要搜索的内容"
            enter-button="搜索"
            size="large"
            @search="onSearch"
        />
        <a-button class="button1" v-if="isUserType==='1'&&isPermissions==='1'"  type="danger" @click="addTechnological()">发布成果/专利</a-button>
      </div>
      <div class="img-noData" v-if="list.length===0"><img src="@/assets/image/noData.png" alt="" /></div>
      <div class="talent-data-list" v-if="list.length>0">
        <div class="gov-span" v-for="item in list" :key="item.id"  @click="showDetail(item.id)">
        <div class="gov-item" >
          <div class="gov-title">
            <a-row>
              <a-col><img class="img1" style="float: left" :src="item.coverPhoto" ></a-col>
              <a-col><div class=" gov-spam-rz content3" >{{item.name}}</div>
                <div class="content1" >发布者：{{item.contact}}</div>
                <div class="content1" >单位：{{item.releaseUnit}}</div></a-col>
            </a-row>
          </div>
          <div class="gov-date" style="text-align: center;color:#ff9a02;width: 100px">
            {{item.priceUccess===1?'价格面议':'货币'}}
            <br/>
            <a-button v-if="$store.state.user.isPermissions()==='1'||$store.state.user.isPermissions()===undefined" class="button2"  type="danger" @click.stop="showForm(item)"  round>立即申请</a-button>
          </div>
        </div>
        </div>
        <a-pagination :show-total="total => `共 ${total} 条`"  show-quick-jumper v-if="total>5" :pageSize="pageSize"  v-model="pageNum" :total="total" @change="change" show-less-items />
      </div>
    </div>
    <div class="gov-detailT" v-show="detailShow">
<!--      <a-page-header
          title="返回"
          @back="detailShow=false"
      />-->
      <span class="yellow text" style="float: right" v-text="selDetail.priceUccess===1?'价格面议':'货币'"></span>
      <div class="gov-detail-top">
        <div class="gov-detail-top-title">
          {{selDetail.name}}
        </div>
      </div>
      <div class="gov-detail-top">
        <div class="gov-detail-top-date">
          <img class="img2" style="float: left" :src="selDetail.coverPhoto" >
          <span class=""><span class="center-span">成果所有者：</span>{{selDetail.contact}}</span><br/>
          <span class="" ><span class="center-span">持有单位：</span> {{selDetail.releaseUnit}}</span><br/>
          <span class="center-span">转让方式：</span><span class="" v-text="selDetail.priceUccess===1?'价格面议':selDetail.price+'（元）'"></span><br/>
            <a-button v-if="$store.state.user.isPermissions()==='1'||$store.state.user.isPermissions()===undefined" style="margin-bottom: 40px" class="button2" @click="showForm(selDetail)"  type="danger" round>立即申请</a-button>
            <div v-if="$store.state.user.isPermissions()!=='1'&&$store.state.user.isPermissions()!==undefined" style="margin-bottom: 40px" class="button2"></div>
        </div>
      </div>
      <span class="text-heda">详细说明</span>
      <div class="gov-detail-content" v-html="selDetail.resultsShowsThat"></div>
    </div>
    <applicationForm  v-if="visible" tebel="技术名称" :demandName="demandName" :demandId="demandId" :visible="visible"  :type="1"/>
  </div>
</template>
<script>
import {getPoliticsList,selPolitics} from "../../api/company/technology";
import {getDictType} from "../../api/company/dictType";
import applicationForm from "../../views/components/applicationForm.vue";
import {getPermissions, getType} from "../../utils/auth";
export default {
  components:{
    applicationForm
  },
  data() {
    return {
      isPermissions:null,
      isUserType:null,
      demandName:"",
      demandId:null,
      visible:false,
      form:{
        resultsType:null,
        name:'',
      },
      pageNum:1,
      pageSize:5,
      total:10,
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "找技术",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: 2,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      list:[],
    };
  },
  methods: {
    showForm(item){
      if(this.isUserType===undefined ||this.isUserType===null){
        this.$parent.login();
        return
      }
      //console.log(this.visible)
      this.demandName= item.name;
      this.demandId=item.id;
      this.visible=true;
    },
    addTechnological(){
      this.$router.push('/addForm/addTechnological')
    },
    change(){
      getPoliticsList({pageNum:this.pageNum,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
   async changeNav(item) {
      this.detailShow = false;
      //console.log(this.detailShow)
      //todo 切换分类 重新加载数据


      this.activeName = item.name;
      //console.log(item);
      this.form.resultsType=item.id;
     await getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
    onSearch(val) {
      this.form.name=val;
      getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id:id}).then(res=>{
        //console.log(res);
        this.selDetail=res.data;
        //console.log(this.selDetail,"this.selDetail");
      })
    },
  async  init(){
     await  getDictType(5).then(res=>{
        this.navData.navList=res.data
      })
      if(this.$route.query.type){
        this.current = parseInt(this.$route.query.type);
      }
      //console.log("改变current",this.current)
      let name='';
      for (let i = 0; i <this.navData.navList.length ; i++) {
        if(this.navData.navList[i].id===parseInt(this.$route.query.type)){
          name=this.navData.navList[i].name;
        }
      }
      let key=this.$route.query.key===undefined?'':this.$route.query.key;
      this.form.name=key;
      await   this.changeNav({id:this.$route.query.type,name:name});
      this.form.name='';
    }
  },
  mounted() {
    this.isPermissions=getPermissions()
    //console.log(getType(),"===========================")
    this.isUserType=getType();
    //console.log(this.isUserType,"2===========================")
    this.init()
  }
};
</script>
<style lang="scss" scoped>

.talent-data-search {
  margin: 25px 0;
  .talent-nav-search-input {
    width: 419px;
    .ant-btn {
      background: #ff9a02;
      border-color: #ff9a02;
      border-radius: 0;
    }
  }
}
.talent-data-list {
  .gov-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #efefef;
    cursor: pointer;
    .gov-title {
      color: #333333;
      font-size: 16px;
      width: 900px;
    }
    .gov-date {
      color: #999999;
    }
  }
  .gov-span :hover{
    .gov-spam-rz {
      color : #044fc6;
    }
  }
}
.gov-detailT {
  width: 75%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
   // text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 10px;
    line-height: 36px;
  }
}
</style>