/*找技术*/
import request from '@/utils/request'

export function getPoliticsList(data) {
    return request({
        url: '/com/application-table/list/'+data.pageNum+'/'+data.pageSize,
        method: 'post',
        params: data.form
    })
}

export function selPolitics(data) {
    return request({
        url: '/com/application-table/sel',
        method: 'get',
        params: data
    })
}

export function addTechnology(data) {
    return request({
        url: '/com/application-table/add',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}